/* eslint-disable no-return-assign */
import React, { Component } from "react";
import Slider from "react-slick";
import { withStyles } from "@mui/styles";
import { CardMedia, Grid } from "@mui/material";
import { MdClose } from "react-icons/md";

const styles = (theme) => ({
    media: {
        objectFit: "contain",
        height: "calc(85vh - 60px)",
    },
    thumbnail: {
        height: 30,
        width: 30,
        objectFit: "cover",
        cursor: "pointer",
        border: "1px solid #337ab7",
    },
    video: {
        display: "flex",
        height: "calc(85vh - 60px)",
        paddingLeft: "calc(8vw)",
        paddingRight: "calc(8vw)"
    },
    iframeSize: {
        width: '100%',
        height: '100%',
        display: "flex",
        border: 0,
        left: 0,
        right: 0,
        [theme.breakpoints.down("xs")]: {
            height: '50%',
        }
    }
});

class GalleryFullScreen extends Component {
    constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentDidUpdate() {
        const { currentIndex } = this.props;
        // go to media if index is filled
        if (currentIndex > 0) {
            this.slider?.slickGoTo(currentIndex, true)
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            // Do whatever when esc is pressed
            const { handleClose } = this.props;
            if (handleClose) {
                handleClose();
            }
        }
    }

    render() {
        const { medias, classes, open, handleClose } = this.props;

        if (!medias || !open) {
            return null;
        }

        const settings = {
            dots: true,
            dotsClass: "slick-dots slick-thumb",
            arrows: false,
            lazyLoad: true,
            speed: 250,
            slidesToShow: 1,
            slidesToScroll: 1,
            customPaging: (i) => {
                if (medias && medias[i]) {
                    if (medias[i].url) {
                        return (
                            <CardMedia
                                key={`thumb-${Math.random()}`}
                                component="img"
                                className={classes.thumbnail}
                                image={medias[i].thumbnail}
                                onError={(e) => {
                                    e.target.src =
                                        "https://def773hwqc19t.cloudfront.net/website/static/no-image-50.png";
                                }}
                            />
                        );
                    }
                }
                return null;
            },
        };

        return (
            <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', backgroundColor: "black", zIndex: 99999 }}>
                <div style={{ position: 'fixed', top: 30, right: 30, zIndex: 88888 }} >
                    <MdClose
                        style={{
                            color: "white",
                            cursor: "pointer"
                        }}
                        size={80}
                        onClick={handleClose}
                    />
                </div>
                <div style={{ position: 'fixed', top: "5%", left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', zIndex: 77777 }} >
                    <Slider {...settings} ref={(slider) => (this.slider = slider)}>
                        {medias.map((media, index) => {
                            if (media && media.url) {
                                if (media.type === "image") {
                                    return (
                                        <CardMedia
                                            key={`img-${Math.random()}`}
                                            component="img"
                                            className={classes.media}
                                            image={media.url}
                                            onError={(e) => {
                                                e.target.src =
                                                    "https://def773hwqc19t.cloudfront.net/website/static/no-image-300.png";
                                            }}
                                        />
                                    );
                                }
                                if (media.type === "video" || media.type === "iframe") {
                                    return (
                                        <Grid
                                            container
                                            key={`video-${Math.random()}`}
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="center"
                                            className={classes.video}
                                        >
                                            {
                                                media.type === "iframe" ? (
                                                    <iframe
                                                        title="video"
                                                        className={classes.iframeSize}
                                                        src={media.url}
                                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                    ></iframe>
                                                ) : (
                                                    // eslint-disable-next-line jsx-a11y/media-has-caption
                                                    <video
                                                        width="100%"
                                                        height="100%"
                                                        controls
                                                        src={media.url}
                                                    />
                                                )
                                            }
                                        </Grid>
                                    )
                                }
                            }
                            return (<div key={`null${index + 1}`}></div>)
                        })}
                    </Slider>
                    <style>{`
                        .slick-thumb li img {
                            width: 100%;
                            height: 100%;
                            filter: grayscale(100%);
                        }
                        .slick-thumb li.slick-active img {
                            filter: grayscale(0%);
                        }
                        .slick-dots {
                            margin-top: 10px !important;
                            position: initial !important;
                        }
                        .slick-dots li {
                            width: 37px !important;
                            height: 37px !important;
                            margin: 4px !important;
                        }
                    `}</style>
                </div >
            </div >
        );
    }
}

export default withStyles(styles)(GalleryFullScreen);
