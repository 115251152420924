import { createTheme } from '@mui/material/styles';
import { map, orderBy, split, size } from "lodash"

const REGEX_YOUTUBE_URL = /^http(s)?:\/\/(www\.)?youtube.*\.com.*embed.*$/
const REGEX_IS_URL = /^http(s)?:\/\/.*$/

export const defaultThemeOption = {
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#111',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    background: {
      paper: 'white',
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiSvgIcon: {
      // Name of the rule
      root: {
        // Some CSS
        height: 24,
        width: 24,
      },
    },
    MuiInputBase: {
      input: {
        height: 19,
      },
    },
  },
};

export const muiTheme = options => {
  return createTheme(options || {});
};

export const isYoutubeUrl = (url) => {
  const regexYoutubeUrl = new RegExp(REGEX_YOUTUBE_URL)
  console.log("isYoutubeUrl")
  if (regexYoutubeUrl.test(url)) {
    console.log("true")
    return true;
  }
  console.log("false")
  return false;
}

/**
 * Check if the param url is really an url, if not it is the id of the video youtube
 * @param {*} url
 * @returns the url of the video
 */
export const getVideoUrl = (url) => {
  const regexIsUrl = new RegExp(REGEX_IS_URL);
  let result = "";
  if (regexIsUrl.test(url)) {
    result = url;
  } else { // si ce n'est pas une url alors elle contient l'id youtube
    result = `https://www.youtube-nocookie.com/embed/${url}`;
  }
  return result;
}

/**
 * If the url is a youtube video, we retrieve the id to generate the thumbnail, else it is a default image
 * @param {*} url
 * @returns the url of the thumbnail
 */
export const getThumbnail = (url) => {
  let result = "";
  const regexYoutubeUrl = new RegExp(REGEX_YOUTUBE_URL)
  if (regexYoutubeUrl.test(url)) {
    const urlSplitElements = split(url, "/");
    let id = urlSplitElements[size(urlSplitElements) - 1];
    if (id.includes("?")) {
      // eslint-disable-next-line prefer-destructuring
      id = split(id, "?")[0];
    }
    result = "https://img.youtube.com/vi/" + id + "/default.jpg";
  } else { // si ce n'est pas une url youtube alors c'est une image par défaut
    const regexIsUrl = new RegExp(REGEX_IS_URL);
    if (regexIsUrl.test(url)) {
      result = "https://def773hwqc19t.cloudfront.net/website/static/no-image-50.png";
    }
    else {
      result = "https://img.youtube.com/vi/" + url + "/default.jpg";
    }
  }
  return result;
}

/**
 * Transforme l'image principale et/ou la gallerie et/ou les vidéos en variables medias pour les afficher.
 * @param {*} image
 * @param {*} gallery
 * @param {*} videos
 */
export const transformGalleryAndVideosToMedias = (image = null, gallery = null, videos = null) => {
  const medias = [];
  if (image) {
    medias.push({
      url: image,
      type: "image",
      thumbnail: image,
      order_index: 0
    })
  }
  if (gallery) {
    map(gallery, (img) => {
      medias.push({
        url: img.url,
        type: "image",
        thumbnail: img.url,
        order_index: 1
      })
    })
  }
  if (videos) {
    map(videos, (video) => {
      medias.push({
        url: getVideoUrl(video.id),
        type: "iframe",
        thumbnail: getThumbnail(video.id),
        order_index: -1
      })
    })
  }
  return orderBy(medias, ['order_index'], ['asc']);
}